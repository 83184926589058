import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Aside,
  Button,
  ButtonLink,
  ButtonRow,
  FieldGroup,
  ReadOnly,
  Text,
  Box,
  Skeleton,
  Spinner,
  Alert,
  RichTextEditor,
} from "@myob/myob-widgets";
import {
  yearFormatted,
  itemType,
  displayContinent,
  emptyForm,
  yearToInteger,
} from "../Utils/Utils";
import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage, placeholder } from "@cloudinary/react";
import { fill } from "@cloudinary/url-gen/actions/resize";
import {
  getSitesByCivilisationId,
  updateSiteById,
  updateCivilisationById,
} from "../AncientWorldsAPI";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import ItemForm from "./ItemForm";

export default function ItemDetails({
  rowKey,
  row,
  handleClose,
  edit,
  setEdit,
  data,
  activeType,
  setActiveId,
  setActiveType,
  setShowDeleteModal,
  setDbUpdateTrigger,
  toast,
}) {
  const [sites, setSites] = useState([]);
  const [formData, setFormData] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const [ApiError, setApiError] = useState("");
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [saving, setSaving] = useState(false);
  const [initialDescription, setInitialDescription] = useState(
    RichTextEditor.emptyState()
  );
  const cld = new Cloudinary({
    cloud: {
      cloudName: "dtcaswh8v",
    },
  });

  const fetchSites = async (row) => {
    if (row.type === itemType.Site) {
      return;
    }
    try {
      const response = await getSitesByCivilisationId(row.id);
      setSites(response.data);
    } catch (error) {
      console.error("Failed to fetch sites:", error);
    }
  };

  useEffect(() => {
    fetchSites(row);
  }, [row]);

  useEffect(() => {
    if (edit) {
      let form = {};
      if (row.type === itemType.Site) {
        form = {
          name: row.name,
          description: row.description,
          civilisationId: row.civilisationId,
          approxYearBuilt: Math.abs(parseInt(row.approxYearBuilt)),
          yearType: parseInt(row.approxYearBuilt) < 0 ? "BCE" : "CE",
          img: row.img,
        };
      } else {
        form = {
          name: row.name,
          description: row.description,
          continent: row.continent,
          startYear: Math.abs(row.startYear.value),
          endYear: Math.abs(row.endYear.value),
          startYearType: row.startYear.value < 0 ? "BCE" : "CE",
          endYearType: row.endYear.value < 0 ? "BCE" : "CE",
        };
      }
      setFormData(form);
      setInitialDescription(
        RichTextEditor.toEditorState(row.description || "")
      );
    }
  }, [edit, row]);

  const renderImage = (row) => {
    if (row["img"] != null) {
      const myImage = cld.image(row["img"]);
      myImage.resize(fill().width(500).height(400));
      return (
        <div className="imageSize">
          <Skeleton className="imagePlaceholder" width="500px" height="400px" />
          <div className="cloudinaryImage">
            <AdvancedImage
              cldImg={myImage}
              plugins={[placeholder({ mode: "vectorize" })]}
            />
          </div>
        </div>
      );
    }
  };

  const renderYear = (row) => {
    if (row["startYear"] != null && row["endYear"] != null) {
      return (
        <ReadOnly key={"yearRange"} label={"Year range"} name={"yearRange"}>
          <Text as="strong">
            {yearFormatted(row["startYear"])} - {yearFormatted(row["endYear"])}
          </Text>
        </ReadOnly>
      );
    } else if (row["approxYearBuilt"] != null) {
      return (
        <ReadOnly
          key={"approxYearBuilt"}
          label={"Build date"}
          name={"approxYearBuilt"}
        >
          <Text as="strong">{yearFormatted(row["approxYearBuilt"])}</Text>
        </ReadOnly>
      );
    }
  };

  const renderRelatedItems = (row) => {
    return (
      <ReadOnly key={"relatedTo"} label={"Related to"} name={"relatedTo"}>
        {row.type === itemType.Site ? (
          <ButtonLink
            className="underline"
            onClick={() =>
              changeViewedItem(itemType.Civilisation, row.civilisationId)
            }
          >
            {row.civilisation}
          </ButtonLink>
        ) : sites.length === 0 ? (
          "No related sites"
        ) : (
          sites.map((site, index) => {
            return (
              <>
                <ButtonLink
                  className="underline"
                  onClick={() => changeViewedItem(itemType.Site, site.id)}
                >
                  {site.name}
                </ButtonLink>
                {index < sites.length - 1 && ", "}
              </>
            );
          })
        )}
      </ReadOnly>
    );
  };

  const renderDescription = (row) => {
    if (row["description"] != null) {
      return (
        <ReadOnly key={"description"} label={"About"} name={"description"}>
          {parse(DOMPurify.sanitize(row["description"].toString()))}
        </ReadOnly>
      );
    }
  };

  const changeViewedItem = (type, id) => {
    setActiveType(type);
    setActiveId(id);
  };

  const verifiedForm = () => {
    let correct = true;
    let updatedErrorMessages = { ...emptyForm[activeType] };
    for (const [key, value] of Object.entries(formData)) {
      if (
        key !== "img" &&
        (value === null ||
          value === "" ||
          (key === "description" && value === "<p><br></p>"))
      ) {
        updatedErrorMessages[key] = "This field is required";
        correct = false;
      } else if (
        key === "approxYearBuilt" ||
        key === "startYear" ||
        key === "endYear"
      ) {
        if (value % 1 !== 0) {
          updatedErrorMessages[key] = "Year can't be a decimal number";
          correct = false;
        } else if (value <= 0) {
          updatedErrorMessages[key] = "Year can't be a negative number";
          correct = false;
        }
      } else if (
        (key === "yearType" ||
          key === "startYearType" ||
          key === "endYearType") &&
        !(value === "CE" || value === "BCE")
      ) {
        updatedErrorMessages[key] = "Please select a valid year type";
        correct = false;
      } else if (key === "civilisationId" && isNaN(value)) {
        updatedErrorMessages[key] = "Please select a valid civilisation";
        correct = false;
      }
    }
    setErrorMessages(updatedErrorMessages);
    return correct;
  };

  const uploadImg = async () => {
    const formData = new FormData();
    formData.append("file", uploadedFile);
    formData.append("upload_preset", "ancient-worlds");
    const options = { asset_folder: "ancient" };
    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dtcaswh8v/image/upload",
        formData,
        options
      );
      return response.data.public_id;
    } catch (error) {
      console.error("Error uploading image:", error);
      return "";
    }
  };

  const handleSave = async () => {
    const canSave = verifiedForm();
    let success = false;
    var imgPath = null;
    if (canSave) {
      setSaving(true);
      if (uploadedFile != null && uploadedFile.name !== "") {
        imgPath = await uploadImg();
        formData.img = imgPath;
      }
      if (activeType === itemType.Site) {
        const approxYearBuilt = yearToInteger(
          formData.approxYearBuilt,
          formData.yearType
        );
        await updateSiteById(row.id, {
          name: formData.name,
          description: formData.description.toString(),
          civilisationId: formData.civilisationId,
          approxYearBuilt: approxYearBuilt,
          img: formData.img,
        })
          .then((response) => {
            if (response.status === 200) {
              success = true;
            }
            setApiError("");
          })
          .catch((error) => {
            setApiError(error.response.data);
          });
      } else {
        const startYear = yearToInteger(
          formData.startYear,
          formData.startYearType
        );
        const endYear = yearToInteger(formData.endYear, formData.endYearType);
        await updateCivilisationById(row.id, {
          name: formData.name,
          description: formData.description.toString(),
          continent: formData.continent,
          startYear: startYear,
          endYear: endYear,
        })
          .then((response) => {
            if (response.status === 200) {
              success = true;
            }
            setApiError("");
          })
          .catch((error) => {
            setApiError(error.response.data);
          });
      }
      setSaving(false);
    }
    if (success) {
      setUploadedFile(null);
      setDbUpdateTrigger((prev) => prev + 1);
      toast({
        tone: "success",
        close: "auto",
        message: row.type + " updated successfully",
      });
    }
  };

  return (
    <Aside
      key={rowKey}
      header={
        <Aside.Header
          title={row.name}
          onClose={handleClose}
          actions={
            <Aside.Actions className="detailBar">
              <Text
                className="continentText"
                tone="neutralStronger"
                textAlign="center"
              >
                {displayContinent(row.continent)}
              </Text>
              <ButtonRow>
                <Button
                  onClick={() => setEdit(true)}
                  disabled={edit}
                  tone="neutral"
                >
                  Edit
                </Button>
                <Box marginLeft="xs" marginRight="xs">
                  <ButtonLink
                    onClick={() => setShowDeleteModal(true)}
                    tone="danger"
                  >
                    Delete
                  </ButtonLink>
                </Box>
              </ButtonRow>
            </Aside.Actions>
          }
        />
      }
      footer={
        edit ? (
          <ButtonRow>
            <Button tone="success" variant="standard" onClick={handleSave}>
              Save
            </Button>
          </ButtonRow>
        ) : null
      }
    >
      <FieldGroup label={row.name} hideLabel>
        {edit ? (
          <>
            {data
              .filter((item) => item.id === row.id && item.type === row.type)
              .map((item) => {
                return (
                  <div>
                    <ItemForm
                      formItemType={activeType}
                      formData={formData}
                      setFormData={setFormData}
                      errorMessages={errorMessages}
                      setErrorMessages={setErrorMessages}
                      uploadedFile={uploadedFile}
                      setUploadedFile={setUploadedFile}
                      initialDescription={initialDescription}
                    />
                    {ApiError !== "" && (
                      <Alert tone="danger" inline>
                        {ApiError}
                      </Alert>
                    )}
                    {saving ? <Spinner size="medium" /> : <></>}
                  </div>
                );
              })}
          </>
        ) : (
          <>
            {renderImage(row)}
            {renderYear(row)}
            {renderRelatedItems(row)}
            {renderDescription(row)}
          </>
        )}
      </FieldGroup>
    </Aside>
  );
}
