import axios from "axios";

const client = axios.create({
  baseURL: "https://ancient-worlds-app.svc.platform.myobdev.com",
});

function getAllCivilisations() {
  return client.get("/Civilisations");
}

function createCivilisation(data) {
  return client.post("/Civilisations", data);
}

function getCivilisationById(id) {
  return client.get(`/Civilisations/${id}`);
}

function updateCivilisationById(id, data) {
  return client.put(`/Civilisations/${id}`, data);
}

function deleteCivilisationById(id) {
  return client.delete(`/Civilisations/${id}`);
}

function searchCivilisations(query) {
  var params = "";
  if (query.name != null) params += `name=${query.name}&`;
  if (query.startYear != null) params += `startYear=${query.startYear}&`;
  if (query.endYear != null) params += `endYear=${query.endYear}&`;
  if (query.continent != null) params += `continent=${query.continent}`;
  return client.get(`/Civilisations/Search?${params}`);
}

function getSitesByCivilisationId(id) {
  return client.get(`/Civilisations/${id}/Sites`);
}

function getAllSites() {
  return client.get("/Sites");
}

function createSite(data) {
  return client.post("/Sites", data);
}

function getSiteById(id) {
  return client.get(`/Sites/${id}`);
}

function updateSiteById(id, data) {
  return client.put(`/Sites/${id}`, data);
}

function deleteSiteById(id) {
  return client.delete(`/Sites/${id}`);
}

function searchSites(query) {
  var params = "";
  if (query.name != null) params += `name=${query.name}&`;
  if (query.startYear != null) params += `startYear=${query.startYear}&`;
  if (query.endYear != null) params += `endYear=${query.endYear}&`;
  if (query.continent != null) params += `continent=${query.continent}`;
  return client.get(`/Sites/Search?${params}`);
}

function getContinents() {
  return client.get("/Continents");
}

export {
  getAllCivilisations,
  createCivilisation,
  getCivilisationById,
  updateCivilisationById,
  deleteCivilisationById,
  searchCivilisations,
  getSitesByCivilisationId,
  getAllSites,
  createSite,
  getSiteById,
  updateSiteById,
  deleteSiteById,
  searchSites,
  getContinents,
};
