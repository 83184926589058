import { ThemeProvider, ToastProvider } from "@myob/myob-widgets";
import MainPage from "./MainPage";

function App() {
  return (
    <ThemeProvider theme="classic">
      <ToastProvider>
        <MainPage />
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App;
