import React from "react";
import { Tooltip, Label } from "@myob/myob-widgets";
import {
  yearFormatted,
  currentYear,
  yearSliderLowerBound,
} from "../Utils/Utils.js";
import "../Style/slider.css";

export default function YearRangeSlider({ yearRange, setYearRange }) {
  const [isValueVisible, setIsValueVisible] = React.useState(false);

  const onChangeLowerValue = (event) => {
    let value = parseInt(event.target.value, 10);
    if (value === 0) {
      value = yearRange.lower < 0 ? 1 : -1;
    } else if (value > yearRange.upper) {
      value = yearRange.upper;
    }
    event.target.value = value;
    setYearRange({ ...yearRange, lower: value });
    setIsValueVisible(true);
  };
  const onChangeUpperValue = (event) => {
    let value = parseInt(event.target.value, 10);
    if (value === 0) {
      value = yearRange.upper < 0 ? 1 : -1;
    } else if (value < yearRange.lower) {
      value = yearRange.lower;
    }
    event.target.value = value;
    setYearRange({ ...yearRange, upper: value });
    setIsValueVisible(true);
  };

  const bottomOfRange = yearRange.lower === yearSliderLowerBound ? "<" : "";

  return (
    <div id="yearInputContainer">
      <Label id="mainLabel">Date Range</Label>
      <div id="rangeContainer">
        {/* not Feelix' intended use of Tooltip but it had the exact aesthetic of the value label I needed */}
        <Tooltip
          trigger="none"
          isOpen={isValueVisible}
          triggerContent={<div id="valueLabel">label</div>}
        >
          {bottomOfRange}
          {yearFormatted(yearRange.lower)} - {yearFormatted(yearRange.upper)}
        </Tooltip>
        <div id="yearRange">
          <div className="slider-container">
            <input
              id="lowerSlider"
              className="slider"
              type="range"
              value={yearRange.lower}
              min={yearSliderLowerBound}
              max={currentYear}
              onChange={onChangeLowerValue}
            />
          </div>
          <div className="sliderContainer">
            <input
              id="upperSlider"
              className="slider"
              type="range"
              value={yearRange.upper}
              min={yearSliderLowerBound}
              max={currentYear}
              onChange={onChangeUpperValue}
            />
          </div>
          <Label id="minLabel">BCE</Label>
          <Label id="maxLabel">CE</Label>
        </div>
      </div>
    </div>
  );
}
