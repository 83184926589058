import React, { useState, useEffect } from "react";
import { searchSites, searchCivilisations } from "./AncientWorldsAPI";
import {
  MasterDetailTemplate,
  Box,
  useToast,
  Table,
  BulkActions,
  Button,
} from "@myob/myob-widgets";
import CreateItemHeader from "./Components/CreateItemHeader.js";
import CreateItemModal from "./Components/CreateItemModal.js";
import TabSelection from "./Components/TabSelection.js";
import Filters from "./Components/Filters.js";
import TableHeader from "./Components/TableHeader.js";
import TableBody from "./Components/TableBody.js";
import ItemDetails from "./Components/ItemDetails.js";
import ConfirmBulkDeleteModal from "./Components/ConfirmBulkDeleteModal.js";
import ConfirmDeleteModal from "./Components/ConfirmDeleteModal.js";
import { itemType, tabs, tableColumns } from "./Utils/Utils.js";
import { sort, applySort } from "./Utils/Sorting.js";
import "./Style/style.css";

export default function MainPage() {
  const [checkedTab, setCheckedTab] = useState(tabs.All);
  const [sortDirection, setSortDirection] = useState("ascending");
  const [sortName, setSortName] = useState("name");
  const [showDetail, setShowDetail] = useState(false);
  const [edit, setEdit] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [activeType, setActiveType] = useState("");
  const [apiData, setApiData] = useState([]);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({
    name: null,
    startYear: null,
    endYear: null,
    continent: null,
  });
  const [columns, setColumns] = useState(tableColumns);
  const [showItemCreationModal, setShowItemCreationModal] = useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemCreationType, setItemCreationType] = useState(
    itemType.Civilisation
  );
  const [dbUpdateTrigger, setDbUpdateTrigger] = useState(0);
  const toast = useToast();

  useEffect(() => {
    var sortData = [...apiData];
    if (checkedTab === tabs.Sites) {
      setColumns(
        tableColumns.map((column) =>
          column.key === "yearRange"
            ? { ...column, visible: false }
            : { ...column }
        )
      );
      sortData = sortData.filter((d) => d.type === itemType.Site);
    } else if (checkedTab === tabs.Civilisations) {
      setColumns(
        tableColumns.map((column) =>
          column.key === "approxYearBuilt" || column.key === "civilisation"
            ? { ...column, visible: false }
            : { ...column }
        )
      );
      sortData = sortData.filter((d) => d.type === itemType.Civilisation);
    } else {
      setColumns(tableColumns);
    }
    setData(
      applySort(sortData, sort[sortName], sortDirection === "descending")
    );
  }, [apiData, checkedTab, sortDirection, sortName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sitesPromise = searchSites(query);
        const civilisationsPromise = searchCivilisations(query);

        const [sitesResponse, civilisationsResponse] = await Promise.all([
          sitesPromise,
          civilisationsPromise,
        ]);

        const sites = sitesResponse.data.map((site) => ({
          ...site,
          type: itemType.Site,
          isSelected: false,
          civilisation: site.civilisationName,
          continent: site.continentName,
        }));
        const civilisations = civilisationsResponse.data.map(
          (civilisation) => ({
            ...civilisation,
            type: itemType.Civilisation,
            isSelected: false,
            civilisation: civilisation.name,
          })
        );

        const combinedData = sites.concat(civilisations);
        setApiData(combinedData);
        setShowDetail(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [query, dbUpdateTrigger]);

  const handleOnChangeTab = (event) => {
    setShowDetail(false);
    setActiveId("");
    setActiveType("");
    setCheckedTab(event.target.name);
  };

  const handleCloseDetailsWithoutSave = () => {
    setEdit(false);
    setShowDetail(false);
    setActiveId("");
    setActiveType("");
  };

  const onRowSelect = (row) => {
    setEdit(false);
    setShowDetail(true);
    setActiveId(row.id);
    setActiveType(row.type);
  };

  const selectAll = (isSelected) => {
    setData(data.map((d) => ({ ...d, isSelected })));
  };

  const selectItem = (item, isSelected) => {
    setData(data.map((d) => (d === item ? { ...item, isSelected } : d)));
  };

  const onSort = (column, nextSortDirection) => {
    setSortDirection(nextSortDirection);
    setSortName(column);
    setData(applySort(data, sort[column], nextSortDirection === "descending"));
  };

  const selectedCount = data.filter((d) => d.isSelected).length;

  const renderBulkActions = (selectedCount) =>
    selectedCount === 0 ? (
      false
    ) : (
      <BulkActions>
        <Button
          tone="neutral"
          variant="standard"
          onClick={() => setShowBulkDeleteModal(true)}
        >
          Delete
        </Button>
      </BulkActions>
    );

  return (
    <Box padding="xl" backgroundColor="neutralWeakest">
      <MasterDetailTemplate
        containerClassName="pageContentTemplate"
        pageHead={
          <CreateItemHeader
            setShowModal={setShowItemCreationModal}
            setItemCreationType={setItemCreationType}
          />
        }
        fluid
        detailWidth="540px"
        filterBar={<Filters setQuery={setQuery} />}
        master={
          <Table
            className="table"
            onRowSelect={onRowSelect}
            hasActions
            variant="card"
            responsiveLayout="scrollX"
          >
            {renderBulkActions(selectedCount)}
            <TableHeader
              columns={columns}
              selectedCount={selectedCount}
              selectAll={selectAll}
              data={data}
              sortName={sortName}
              sortDirection={sortDirection}
              sort={sort}
              onSort={onSort}
            />
            <TableBody
              columns={columns}
              data={data}
              onRowSelect={onRowSelect}
              activeId={activeId}
              activeType={activeType}
              selectItem={selectItem}
            />
          </Table>
        }
        detail={data
          .filter((row) => row.id === activeId && row.type === activeType)
          .map((row) => (
            <ItemDetails
              key={`${row.id}-${row.type}`}
              row={row}
              handleClose={handleCloseDetailsWithoutSave}
              edit={edit}
              setEdit={setEdit}
              data={data}
              activeType={activeType}
              setActiveId={setActiveId}
              setActiveType={setActiveType}
              setShowDeleteModal={setShowDeleteModal}
              setDbUpdateTrigger={setDbUpdateTrigger}
              toast={toast}
            />
          ))}
        showDetail={showDetail}
        subHeadChildren={
          <>
            <TabSelection onChange={handleOnChangeTab} checked={checkedTab} />
            <CreateItemModal
              showModal={showItemCreationModal}
              setShowModal={setShowItemCreationModal}
              toast={toast}
              formItemType={itemCreationType}
              setDbUpdateTrigger={setDbUpdateTrigger}
            />
            <ConfirmBulkDeleteModal
              showModal={showBulkDeleteModal}
              setShowModal={setShowBulkDeleteModal}
              toast={toast}
              data={data}
              setData={setData}
              setDbUpdateTrigger={setDbUpdateTrigger}
              setShowDetail={setShowDetail}
              setActiveId={setActiveId}
              setActiveType={setActiveType}
            />
            <ConfirmDeleteModal
              showModal={showDeleteModal}
              setShowModal={setShowDeleteModal}
              toast={toast}
              activeId={activeId}
              activeType={activeType}
              setShowDetail={setShowDetail}
              setDbUpdateTrigger={setDbUpdateTrigger}
            />
          </>
        }
      />
    </Box>
  );
}
