import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Modal,
  Button,
  Alert,
  Spinner,
  RichTextEditor,
} from "@myob/myob-widgets";
import { createCivilisation, createSite } from "../AncientWorldsAPI";
import { itemType, emptyForm, yearToInteger } from "../Utils/Utils.js";
import ItemForm from "./ItemForm.js";

export default function CreateItemModal({
  showModal,
  setShowModal,
  toast,
  formItemType,
  setDbUpdateTrigger,
}) {
  const [formData, setFormData] = useState(emptyForm[formItemType]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [ApiError, setApiError] = useState("");
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [saving, setSaving] = useState(false);
  const [initialDescription, setInitialDescription] = useState(
    RichTextEditor.emptyState()
  );

  useEffect(() => {
    if (formItemType === itemType.Site) {
      setFormData(emptyForm.Site);
      setErrorMessages(emptyForm.Site);
    } else {
      setFormData(emptyForm.Civilisation);
      setErrorMessages(emptyForm.Civilisation);
    }
  }, [formItemType, setErrorMessages, setFormData]);

  const verifiedForm = () => {
    let correct = true;
    let updatedErrorMessages = { ...emptyForm[formItemType] };
    for (const [key, value] of Object.entries(formData)) {
      if (
        key !== "img" &&
        (value === null ||
          value === "" ||
          (key === "description" && value === "<p><br></p>"))
      ) {
        updatedErrorMessages[key] = "This field is required";
        correct = false;
      } else if (
        key === "approxYearBuilt" ||
        key === "startYear" ||
        key === "endYear"
      ) {
        if (value % 1 !== 0) {
          updatedErrorMessages[key] = "Year can't be a decimal number";
          correct = false;
        } else if (value <= 0) {
          updatedErrorMessages[key] = "Year can't be a negative number";
          correct = false;
        }
      } else if (
        (key === "yearType" ||
          key === "startYearType" ||
          key === "endYearType") &&
        !(value === "CE" || value === "BCE")
      ) {
        updatedErrorMessages[key] = "Please select a valid year type";
        correct = false;
      } else if (key === "civilisationId" && isNaN(value)) {
        updatedErrorMessages[key] = "Please select a valid civilisation";
        correct = false;
      }
    }
    setErrorMessages(updatedErrorMessages);
    return correct;
  };

  const uploadImg = async () => {
    const formData = new FormData();
    formData.append("file", uploadedFile);
    formData.append("upload_preset", "ancient-worlds");
    const options = { asset_folder: "ancient" };
    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dtcaswh8v/image/upload",
        formData,
        options
      );
      return response.data.public_id;
    } catch (error) {
      console.error("Error uploading image:", error);
      return "";
    }
  };

  const handleSave = async () => {
    const canSave = verifiedForm();
    let success = false;
    var imgPath = null;
    if (canSave) {
      setSaving(true);
      if (uploadedFile != null && uploadedFile.name !== "") {
        imgPath = await uploadImg();
        formData.img = imgPath;
      }
      if (formItemType === itemType.Site) {
        const approxYearBuilt = yearToInteger(
          formData.approxYearBuilt,
          formData.yearType
        );
        await createSite({
          name: formData.name,
          description: formData.description.toString(),
          civilisationId: formData.civilisationId,
          approxYearBuilt: approxYearBuilt,
          img: formData.img,
        })
          .then((response) => {
            if (response.status === 200) {
              success = true;
            }
            setApiError("");
          })
          .catch((error) => {
            setApiError(error.response.data);
          });
      } else {
        const startYear = yearToInteger(
          formData.startYear,
          formData.startYearType
        );
        const endYear = yearToInteger(formData.endYear, formData.endYearType);
        await createCivilisation({
          name: formData.name,
          description: formData.description.toString(),
          continent: formData.continent,
          startYear: startYear,
          endYear: endYear,
        })
          .then((response) => {
            if (response.status === 200) {
              success = true;
            }
            setApiError("");
          })
          .catch((error) => {
            setApiError(error.response.data);
          });
      }
      setSaving(false);
    }
    if (success) {
      setUploadedFile(null);
      setShowModal(false);
      toast({
        tone: "success",
        close: "auto",
        message: formItemType + " created successfully",
      });
      setDbUpdateTrigger((prev) => prev + 1);
    }
  };

  const onCancel = () => {
    setApiError("");
    setShowModal(false);
  };

  return (
    <div>
      {showModal && (
        <Modal
          title={"Create new " + formItemType.toLowerCase()}
          onCancel={onCancel}
        >
          <Modal.Body>
            <ItemForm
              formItemType={formItemType}
              formData={formData}
              setFormData={setFormData}
              errorMessages={errorMessages}
              setErrorMessages={setErrorMessages}
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
              initialDescription={initialDescription}
            />
            {ApiError !== "" && (
              <Alert tone="danger" inline>
                {ApiError}
              </Alert>
            )}
            {saving ? <Spinner size="medium" /> : <></>}
          </Modal.Body>
          <Modal.Footer>
            <Button tone="neutral" variant="standard" onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={handleSave}>Save</Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
