import React from "react";
import { Modal, Button, Text } from "@myob/myob-widgets";
import { deleteCivilisationById, deleteSiteById } from "../AncientWorldsAPI";
import { itemType } from "../Utils/Utils.js";

export default function ConfirmDeleteModal({
  showModal,
  setShowModal,
  toast,
  activeId,
  activeType,
  setShowDetail,
  setDbUpdateTrigger,
}) {
  const onCancel = () => {
    setShowModal(false);
  };
  const onDelete = async () => {
    try {
      if (activeType === itemType.Civilisation) {
        await deleteCivilisationById(activeId);
      } else {
        await deleteSiteById(activeId);
      }
      toast({
        tone: "success",
        close: "auto",
        message: "Deleted item",
      });
      setDbUpdateTrigger((prev) => prev + 1);
      setShowDetail(false);
    } catch (error) {
      console.error("Failed to delete item:", error);
      toast.error("Failed to delete item");
    }
    setShowModal(false);
  };

  return (
    <div>
      {showModal && (
        <Modal title={"Confirm delete"} onCancel={onCancel}>
          <Modal.Body>
            <Text>Are you sure you want to delete this entry?</Text>
          </Modal.Body>
          <Modal.Footer>
            <Button tone="neutral" variant="standard" onClick={onCancel}>
              Cancel
            </Button>
            <Button tone="danger" variant="standard" onClick={onDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
