import React from "react";
import { Table, Checkbox } from "@myob/myob-widgets";

export default function TableHeader({
  columns,
  selectedCount,
  selectAll,
  data,
  sortName,
  sortDirection,
  sort,
  onSort,
}) {
  return (
    <Table.Header>
      <Table.HeaderItem width="auto">
        <Checkbox
          name="bulk-select"
          label="Bulk select"
          hideLabel
          onChange={(e) => selectAll(e.target.checked)}
          checked={(data.length && selectedCount === data.length) === true}
          indeterminate={selectedCount > 0 && selectedCount !== data.length}
        />
      </Table.HeaderItem>
      {columns.map(
        (column) =>
          column.visible && (
            <Table.HeaderItem
              key={column.key}
              columnName={column.description}
              sortName={column.key}
              sortDirection={
                column.key === sortName ? sortDirection : "unsorted"
              }
              onSort={sort[column.key] && onSort}
              align={column.key === "img" ? "right" : "left"}
            >
              {column.description}
            </Table.HeaderItem>
          )
      )}
    </Table.Header>
  );
}
