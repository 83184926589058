import React from "react";
import { PageHead, Button, AddIcon } from "@myob/myob-widgets";
import { itemType } from "../Utils/Utils";

export default function CreateItemHeader({
  setShowModal,
  setItemCreationType,
}) {
  return (
    <PageHead title="Ancient worlds database">
      <Button
        tone="neutral"
        variant="standard"
        icon={<AddIcon />}
        onClick={async () => {
          await setItemCreationType(itemType.Civilisation);
          setShowModal(true);
        }}
      >
        Create {itemType.Civilisation.toLowerCase()}
      </Button>
      <Button
        tone="success"
        variant="standard"
        icon={<AddIcon />}
        onClick={async () => {
          await setItemCreationType(itemType.Site);
          setShowModal(true);
        }}
      >
        Create {itemType.Site.toLowerCase()}
      </Button>
    </PageHead>
  );
}
