import React from "react";
import { Table, Checkbox, ImageIcon } from "@myob/myob-widgets";
import { yearFormatted, displayContinent } from "../Utils/Utils";

export default function TableBody({
  columns,
  data,
  onRowSelect,
  activeId,
  activeType,
  selectItem,
}) {
  const renderData = (row, column) => {
    if (
      column.key === "yearRange" &&
      row["startYear"] != null &&
      row["endYear"] != null
    ) {
      return (
        <Table.RowItem key={column.key} columnName={column.description}>
          {yearFormatted(row["startYear"])} - {yearFormatted(row["endYear"])}
        </Table.RowItem>
      );
    } else if (column.key === "approxYearBuilt" && row[column.key] != null) {
      return (
        <Table.RowItem key={column.key} columnName={column.description}>
          {yearFormatted(row[column.key])}
        </Table.RowItem>
      );
    } else if (column.key === "img" && row[column.key] != null) {
      return (
        <Table.RowItem
          key={column.key}
          columnName={column.description}
          align="right"
        >
          <ImageIcon />
        </Table.RowItem>
      );
    } else if (column.key === "continent") {
      return (
        <Table.RowItem key={column.key} columnName={column.description}>
          {displayContinent(row[column.key])}
        </Table.RowItem>
      );
    } else {
      return (
        <Table.RowItem key={column.key} columnName={column.description}>
          {row[column.key]}
        </Table.RowItem>
      );
    }
  };

  return (
    <Table.Body onRowSelect={onRowSelect}>
      {data.map((row) => (
        <Table.Row
          key={row.id + "-" + row.type}
          isSelected={row.isSelected}
          isActive={row.id === activeId && row.type === activeType}
          rowData={{ id: row.id.toString(), type: row.type }}
          onRowSelect={() => onRowSelect(row)}
        >
          <Table.RowItem
            width="auto"
            textWrap="wrap"
            cellRole="checkbox"
            valign="middle"
          >
            <Checkbox
              name={`${row.type}-${row.id}-select`}
              label={`Select row ${row.type}-${row.id}`}
              hideLabel
              onChange={(e) => selectItem(row, e.target.checked)}
              checked={row.isSelected}
            />
          </Table.RowItem>
          {columns.map((column) => column.visible && renderData(row, column))}
        </Table.Row>
      ))}
    </Table.Body>
  );
}
