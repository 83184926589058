const stringCompare = (a, b) => {
  if (!a && !b) {
    return 0;
  } else if (!a) {
    return -1;
  } else if (!b) {
    return 1;
  }
  const stringA = a.toUpperCase();
  const stringB = b.toUpperCase();
  if (stringA < stringB) {
    return -1;
  }
  if (stringA > stringB) {
    return 1;
  }
  // names must be equal
  return 0;
};

const yearCompare = (a, b) => {
  if (!a && !b) {
    return 0;
  } else if (!a) {
    return -1;
  } else if (!b) {
    return 1;
  }
  const yearA = parseInt(a) ? parseInt(a) : a.value;
  const yearB = parseInt(b) ? parseInt(b) : b.value;
  if (yearA < yearB) {
    return -1;
  }
  if (yearA > yearB) {
    return 1;
  }
  // start years must be equal
  return 0;
};

const yearRangeCompare = (startYearA, startYearB, endYearA, endYearB) => {
  let compare = yearCompare(startYearA, startYearB);
  if (compare === 0) {
    compare = yearCompare(endYearA, endYearB);
  }
  // start years must be equal
  return compare;
};

export const sort = {
  name: (a, b) => stringCompare(a.name, b.name),
  continent: (a, b) => stringCompare(a.continent, b.continent),
  approxYearBuilt: (a, b) => yearCompare(a.approxYearBuilt, b.approxYearBuilt),
  yearRange: (a, b) =>
    yearRangeCompare(a.startYear, b.startYear, a.endYear, b.endYear),
  civilisation: (a, b) => stringCompare(a.civilisation, b.civilisation),
};

export const applySort = (data, sortFn, descending) => {
  const result = data.slice();

  result.sort(sortFn);

  return descending ? result.reverse() : result;
};
