import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  RichTextEditor,
  DropZone,
  FieldGroup,
  Text,
  FileChip,
} from "@myob/myob-widgets";
import { getAllCivilisations, getContinents } from "../AncientWorldsAPI";
import { itemType, yearFormatted, displayContinent } from "../Utils/Utils.js";

export default function ItemForm({
  formItemType,
  formData,
  setFormData,
  errorMessages,
  setErrorMessages,
  uploadedFile,
  setUploadedFile,
  initialDescription,
}) {
  const [continents, setContinents] = useState([]);
  const [civilisations, setCivilisations] = useState([]);
  const [editorValue, setEditorValue] = useState(initialDescription);

  useEffect(() => {
    setEditorValue(initialDescription);
  }, [initialDescription]);

  const fetchContinents = async () => {
    try {
      const response = await getContinents();
      setContinents(response.data);
    } catch (error) {
      console.error("Failed to fetch continents:", error);
    }
  };

  const fetchCivilisations = async () => {
    try {
      const response = await getAllCivilisations();
      setCivilisations(response.data);
    } catch (error) {
      console.error("Failed to fetch civilisations:", error);
    }
  };

  useEffect(() => {
    fetchContinents();
    fetchCivilisations();
  }, []);

  const updateForm = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setErrorMessages({
      ...errorMessages,
      [key]: "",
    });
    setFormData({ ...formData, [key]: value });
  };

  const updateFormDescription = (value) => {
    setEditorValue(value);
    setErrorMessages({
      ...errorMessages,
      description: "",
    });
    let descriptionString = RichTextEditor.toHtml(value).toString();
    setFormData({ ...formData, description: descriptionString });
    return;
  };

  const handleImgDrop = async (fileArray, errors) => {
    if (errors.length === 0) {
      await setUploadedFile(fileArray[0]);
    } else {
      setErrorMessages({ ...errorMessages, img: errors.join(", ") });
    }
  };

  const handleImgSelect = async (file) => {
    await setUploadedFile(file);
    setErrorMessages({ ...errorMessages, img: "" });
  };

  const handleImgRemove = () => {
    setUploadedFile(null);
  };

  const mockFileChip = (
    <FileChip
      state="default"
      name={uploadedFile?.name}
      onRemove={handleImgRemove}
    />
  );

  const dropZone = (
    <DropZone
      name="img"
      onDrop={handleImgDrop}
      onFileSelected={handleImgSelect}
      singleFileSelect
      acceptedFileTypes=".jpg, .jpeg, .png, .gif, .bmp, .tiff, .ico, .pdf, .eps, .psd, .svg, .webp, .jxr, .wdp"
      textCustomizations={{
        labelText: "Drop image here to upload, or",
        buttonText: "browse for image",
        activeDragText: "Drop image here",
      }}
    >
      {errorMessages["img"] != null && errorMessages["img"] !== "" ? (
        <Text tone="dangerStrong" as="span" margin="3xs">
          {errorMessages["img"]}
        </Text>
      ) : (
        <></>
      )}
    </DropZone>
  );

  return (
    <div>
      <FieldGroup label="Form" hideLabel>
        <Input
          name="name"
          label="Name"
          placeholder={formItemType + " name"}
          requiredLabel="This field is required"
          errorMessage={errorMessages.name ?? ""}
          onChange={updateForm}
          value={formData.name}
        />
        <RichTextEditor
          className="textbox"
          label="Description"
          name="description"
          onChange={updateFormDescription}
          value={editorValue}
          requiredLabel="This field is required"
          hideLabel={false}
          errorMessage={errorMessages.description ?? ""}
        />
        {formItemType === itemType.Site ? (
          <>
            <Select
              name="civilisationId"
              label="Civilisation"
              requiredLabel="This field is required"
              defaultValue=""
              errorMessage={errorMessages.civilisationId ?? ""}
              onChange={updateForm}
              value={formData.civilisationId}
            >
              <Select.Option
                value=""
                label="Please select a civilisation"
                disabled
              />
              {civilisations.map((civilisation) => (
                <Select.Option
                  value={civilisation.id}
                  label={
                    civilisation.name +
                    " (" +
                    yearFormatted(civilisation.startYear) +
                    " - " +
                    yearFormatted(civilisation.endYear) +
                    ")"
                  }
                />
              ))}
            </Select>
            <div className="yearInput">
              <Input
                name="approxYearBuilt"
                label="Build date"
                type="number"
                placeholder={"YYYY"}
                requiredLabel="This field is required"
                onChange={updateForm}
                value={formData.approxYearBuilt}
                errorMessage={errorMessages.approxYearBuilt ?? ""}
              />
              <Select
                name="yearType"
                label="Type"
                requiredLabel="This field is required"
                width="xs"
                defaultValue=""
                value={formData.yearType}
                onChange={updateForm}
                errorMessage={errorMessages.yearType ?? ""}
              >
                <Select.Option value="" label="-" disabled />
                <Select.Option value="CE" label="CE" />
                <Select.Option value="BCE" label="BCE" />
              </Select>
              <div></div>
            </div>
          </>
        ) : (
          <>
            <Select
              name="continent"
              label="Continent"
              requiredLabel="This field is required"
              defaultValue=""
              value={formData.continent}
              onChange={updateForm}
              errorMessage={errorMessages.continent ?? ""}
            >
              <Select.Option
                value=""
                label="Please select a continent"
                disabled
              />
              {continents.map((continent) => (
                <Select.Option
                  value={continent}
                  label={displayContinent(continent)}
                />
              ))}
            </Select>
            <div className="yearRangeInput">
              <Input
                name="startYear"
                label="Start year"
                type="number"
                placeholder={"YYYY"}
                requiredLabel="This field is required"
                onChange={updateForm}
                value={formData.startYear}
                errorMessage={errorMessages.startYear ?? ""}
              />
              <Select
                name="startYearType"
                label="Type"
                requiredLabel="This field is required"
                width="xs"
                defaultValue=""
                value={formData.startYearType}
                onChange={updateForm}
                errorMessage={errorMessages.startYearType ?? ""}
              >
                <Select.Option value="" label="-" disabled />
                <Select.Option value="CE" label="CE" />
                <Select.Option value="BCE" label="BCE" />
              </Select>
              <Input
                name="endYear"
                label="End Year"
                type="number"
                placeholder={"YYYY"}
                requiredLabel="This field is required"
                onChange={updateForm}
                value={formData.endYear}
                errorMessage={errorMessages.endYear ?? ""}
              />
              <Select
                name="endYearType"
                label="Type"
                requiredLabel="This field is required"
                width="xs"
                defaultValue=""
                onChange={updateForm}
                value={formData.endYearType}
                errorMessage={errorMessages.endYearType ?? ""}
              >
                <Select.Option value="" label="-" disabled />
                <Select.Option value="CE" label="CE" />
                <Select.Option value="BCE" label="BCE" />
              </Select>
              <div></div>
            </div>
          </>
        )}
      </FieldGroup>
      {formItemType === itemType.Site ? (
        <FieldGroup>{uploadedFile ? mockFileChip : dropZone}</FieldGroup>
      ) : (
        <></>
      )}
    </div>
  );
}
