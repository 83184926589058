import React from "react";

import { SelectPill, SelectPillGroup } from "@myob/myob-widgets";
import { tabs } from "../Utils/Utils.js";

export default function TabSelection({ onChange, checked }) {
  return (
    <SelectPillGroup label="Tabs" selectionType="single" hideLabel>
      <SelectPill
        label={tabs.All}
        name={tabs.All}
        value={tabs.All}
        onChange={onChange}
        checked={checked === tabs.All}
      />
      <SelectPill
        label={tabs.Civilisations}
        name={tabs.Civilisations}
        value={tabs.Civilisations}
        onChange={onChange}
        checked={checked === tabs.Civilisations}
      />
      <SelectPill
        label={tabs.Sites}
        name={tabs.Sites}
        value={tabs.Sites}
        onChange={onChange}
        checked={checked === tabs.Sites}
      />
    </SelectPillGroup>
  );
}
