import React from "react";
import { Modal, Button, Text } from "@myob/myob-widgets";
import { deleteCivilisationById, deleteSiteById } from "../AncientWorldsAPI";
import { itemType } from "../Utils/Utils.js";

export default function ConfirmBulkDeleteModal({
  showModal,
  setShowModal,
  toast,
  data,
  setDbUpdateTrigger,
  setShowDetail,
  setActiveId,
  setActiveType,
}) {
  const onCancel = () => {
    setShowModal(false);
  };
  const onDelete = () => {
    data.forEach(async (row) => {
      if (row.isSelected) {
        try {
          if (row.type === itemType.Civilisation) {
            await deleteCivilisationById(row.id);
          } else {
            await deleteSiteById(row.id);
          }
          toast({
            tone: "success",
            close: "auto",
            message: "Deleted " + row.name,
          });
        } catch (error) {
          console.error("Failed to delete an item:", error);
          toast.error("Failed to delete an item");
        }
      }
    });
    setShowDetail(false);
    setActiveId("");
    setActiveType("");
    setDbUpdateTrigger((prev) => prev + 1);
    setShowModal(false);
  };

  return (
    <div>
      {showModal && (
        <Modal title={"Confirm bulk delete"} onCancel={onCancel}>
          <Modal.Body>
            <Text>Are you sure you want to delete these entries?</Text>
          </Modal.Body>
          <Modal.Footer>
            <Button tone="neutral" variant="standard" onClick={onCancel}>
              Cancel
            </Button>
            <Button tone="danger" variant="standard" onClick={onDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
