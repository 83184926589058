const yearOfProjectKata = 2024;

export const currentYear = new Date().getFullYear();
export const yearSliderLowerBound = -5000;

export const tabs = {
  All: "All",
  Civilisations: "Civilisations",
  Sites: "Sites",
};

export const itemType = {
  Civilisation: "Civilisation",
  Site: "Site",
};

export const tableColumns = [
  { key: "name", description: "Name", visible: true },
  { key: "continent", description: "Continent", visible: true },
  { key: "approxYearBuilt", description: "Build date", visible: true },
  { key: "yearRange", description: "Year range", visible: true },
  { key: "civilisation", description: "Civilisation", visible: true },
  { key: "img", description: "Image", visible: true },
];

export const detailsLabels = {
  name: "Name",
  continent: "Continent",
  img: "Image",
  yearRange: "Year range",
  approxYearBuilt: "Build date",
  civilisation: "Related to",
  sites: "Related to",
  description: "About",
};

export const yearFormatted = (year) => {
  const yearNum = parseInt(year) ? parseInt(year) : year.value;
  if (yearNum >= yearOfProjectKata) {
    return "now";
  } else if (yearNum < 0) {
    return Math.abs(yearNum) + " BCE";
  } else return yearNum + " CE";
};

export const yearToInteger = (number, type) => {
  const yearValue = parseInt(number);
  const types = {
    BCE: -1,
    CE: 1,
  };
  return types[type] * Math.abs(yearValue);
};

export const emptyForm = {
  Site: {
    name: "",
    description: "",
    civilisationId: "",
    approxYearBuilt: "",
    yearType: "",
    img: null,
  },
  Civilisation: {
    name: "",
    description: "",
    continent: "",
    startYear: "",
    endYear: "",
    startYearType: "",
    endYearType: "",
  },
};

export const displayContinent = (continent) => {
  if (continent === "NorthAmerica") {
    return "North America";
  } else if (continent === "SouthAmerica") {
    return "South America";
  } else {
    return continent;
  }
};
