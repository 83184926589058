import React, { useState, useEffect } from "react";
import { FilterBar, Search, Select } from "@myob/myob-widgets";
import {
  currentYear,
  yearSliderLowerBound,
  displayContinent,
} from "../Utils/Utils.js";
import { getContinents } from "../AncientWorldsAPI";
import YearRangeSlider from "./YearRangeSlider";

const startingYearRange = {
  lower: yearSliderLowerBound,
  upper: currentYear,
};

export default function Filters({ setQuery }) {
  const [continents, setContinents] = useState([]);
  const [yearRange, setYearRange] = useState(startingYearRange);
  const [filterValues, setfilterValues] = React.useState({
    name: "",
    continent: "All",
  });

  const onSearchNameChange = (event) => {
    setfilterValues({ ...filterValues, name: event.target.value });
  };

  const onContinentChange = (event) => {
    setfilterValues({ ...filterValues, continent: event.target.value });
  };

  const fetchContinents = async () => {
    try {
      const response = await getContinents();
      setContinents(response.data);
    } catch (error) {
      console.error("Failed to fetch continents:", error);
    }
  };

  function handleReset() {
    setfilterValues({
      name: "",
      continent: "All",
    });
    setYearRange(startingYearRange);
  }

  useEffect(() => {
    fetchContinents();
  }, []);

  useEffect(() => {
    const nameQuery = filterValues.name === "" ? null : filterValues.name;
    const continentQuery =
      filterValues.continent === "All" ? null : filterValues.continent;
    const startQuery =
      yearRange.lower === yearSliderLowerBound ? null : yearRange.lower;
    const endQuery = yearRange.upper === currentYear ? null : yearRange.upper;
    const newQuery = {
      name: nameQuery,
      continent: continentQuery,
      startYear: startQuery,
      endYear: endQuery,
    };
    setQuery(newQuery);
  }, [yearRange, filterValues, setQuery]);

  return (
    <FilterBar onReset={handleReset}>
      <FilterBar.Group>
        <Search
          value={filterValues.name}
          onChange={onSearchNameChange}
          placeholder="Temple..."
          label="Name"
          name="search name"
        />
        <Select
          name="default"
          label="Continent"
          value={filterValues.continent}
          onChange={onContinentChange}
        >
          {continents.map((continent) => (
            <Select.Option
              value={continent}
              label={displayContinent(continent)}
            />
          ))}
          <Select.Option value={"All"} label={"All"} />
        </Select>
        <YearRangeSlider yearRange={yearRange} setYearRange={setYearRange} />
      </FilterBar.Group>
    </FilterBar>
  );
}
